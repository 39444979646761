<template>
  <div>
    <v-navigation-drawer
      :value="isDrawerOpen"
      app
      floating
      width="260"
      class="app-navigation-menu"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-slide-x-transition>
            <h2 class="app-title text--primary">Roomi</h2>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand shaped class="vertical-nav-menu-items pr-5">
        <div v-for="(item, index) of menu" :key="index">
          <nav-menu-link :title="item.name" :to="{ name: item.link }" v-if="item.link != ''"></nav-menu-link>
          <nav-menu-group v-else :title="item.name" :icon="icons.mdiCheckboxBlankCircleOutline">
            <div v-for="(child, num) of item.childs" :key="num">
              <nav-menu-link :title="child.name" :to="{ name: child.link }"></nav-menu-link>
            </div>
          </nav-menu-group>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiApplicationCogOutline,
  mdiCellphoneCog,
  mdiCashMultiple,
  mdiCheckboxBlankCircleOutline,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import axios from 'axios'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiApplicationCogOutline,
        mdiCellphoneCog,
        mdiCashMultiple,
        mdiCheckboxBlankCircleOutline
      },
    }
  },
  data() {
    return {
      userData: {},
      menu: [],
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
    }
  },
  mounted() {
    if (localStorage.getItem('token') === null) {
      this.$router.push('/login')
    } else {
      const user = JSON.parse(localStorage.getItem('user'))
      this.userData = user
      this.getMenu()
    }
  },
  methods: {
    getMenu() {
      const token = localStorage.getItem('token')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}users/menu`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this._grouping_menu(data.data)
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },

    _grouping_menu(menus = []) {
      let temp = []
      for (const item of menus) {
        if (item.parent_id === null) {
          temp.push(item)
        }
      }
      temp.map(item => {
        const filter = menus.filter(menu => {
          return menu.parent_id == item.id
        })
        item.childs = filter
        return item
      })
      this.menu = temp
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
