import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login'
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: ""
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      title: "Profile"
    }
  },
  {
    path: '/menu-frontend',
    name: 'menu-frontend',
    component: () => import('@/views/menu-frontend/MenuFrontend.vue'),
    meta: {
      title: "Menu Frontend"
    }
  },
  {
    path: "/menu-admin",
    name: "menu-admin",
    component: () => import('@/views/menu-admin/MenuAdmin.vue'),
    meta: {
      title: "Menu Admin"
    }
  },
  {
    path: "/permission-menu-admin",
    name: "permission-menu-admin",
    component: () => import('@/views/permission/Permission.vue'),
    meta: {
      title: "Permission Menu Admin"
    }
  },
  {
    path: "/permission-menu-admin/:role_id",
    name: "permission-menu-admin-form",
    component: () => import('@/views/permission/Form.vue'),
    meta: {
      title: "Edit Permission Menu Admin"
    }
  },
  {
    path: "/role",
    name: "role",
    component: () => import('@/views/role/Role.vue'),
    meta: {
      title: "Role"
    }
  },
  {
    path: "/product-category",
    name: "product-category",
    component: () => import('@/views/product-category/ProductCategory.vue'),
    meta: {
      title: "Produk Kategori"
    }
  },
  {
    path: "/product",
    name: "product",
    component: () => import('@/views/product/Product.vue'),
    meta: {
      title: "Produk"
    }
  },
  {
    path: "/product/add",
    name: "product-add",
    component: () => import('@/views/product/Add.vue'),
    meta: {
      title: "Tambah Produk"
    }
  },
  {
    path: "/product/edit/:id",
    name: "product-edit",
    component: () => import('@/views/product/Edit.vue'),
    meta: {
      title: "Edit Produk"
    }
  },
  {
    path: "/purchase-link",
    name: "purchase-link",
    component: () => import('@/views/purchase-link/PurchaseLink.vue'),
    meta: {
      title: "Link Pembelian"
    }
  },
  {
    path: "/app-link",
    name: "app-link",
    component: () => import('@/views/app-link/AppLink.vue'),
    meta: {
      title: "Link Aplikasi"
    }
  },
  {
    path: "/banner",
    name: "banner",
    component: () => import('@/views/banner/Banner.vue'),
    meta: {
      title: "Banner"
    }
  },
  {
    path: '/files',
    name: 'files',
    component: () => import('@/views/file/File.vue'),
    meta: {
      title: "File"
    }
  },
  {
    path: '/files/add',
    name: 'files-add',
    component: () => import('@/views/file/Add.vue'),
    meta: {
      title: "Tambah File"
    }
  },
  {
    path: '/files/:id',
    name: "files-edit",
    component: () => import('@/views/file/Edit.vue'),
    meta: {
      title: "Ubah File"
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/account/Account.vue'),
    meta: {
      title: "Akun"
    }
  },
  {
    path: '/serial-number-product',
    name: 'serial-number-product',
    component: () => import('@/views/serial-number/SerialNumber.vue'),
    meta: {
      title: "Serial Number"
    }
  },
  {
    path: '/e-garansi',
    name: 'e-garansi',
    component: () => import('@/views/e-guarantee/EGuarantee.vue'),
    meta: {
      title: "E-Garansi"
    }
  },
  {
    path: "/product-page",
    name: "product-page",
    component: () => import('@/views/product-page/ProductPage.vue'),
    meta: {
      title: "Landing Page Product"
    }
  },
  {
    path: "/product-page/add",
    name: "product-page-add",
    component: () => import('@/views/product-page/Add.vue'),
    meta: {
      title: "Add Landing Page Product"
    }
  },
  {
    path: "/product-page/edit/:id",
    name: "product-page-edit",
    component: () => import('@/views/product-page/Edit.vue'),
    meta: {
      title: "Edit Landing Page Product"
    }
  },
  {
    path: '/deperindag',
    name: 'deperindag',
    component: () => import('@/views/deperindag/Deperindag.vue'),
    meta: {
      title: "Deperindag"
    }
  },
  {
    path: '/deperindag-product-model',
    name: 'deperindag-product-model',
    component: () => import('@/views/deperindag-product-model/DeperindagProductModel.vue'),
    meta: {
      title: "Produk Model"
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      title: "404 Not Found"
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    const DEFAULT_TITLE = 'Roomi Dashboard';
    document.title = DEFAULT_TITLE;
    document.title += to.meta.title ? ' - ' + to.meta.title : '';
  });
})

export default router
