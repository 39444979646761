// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const formatRupiah = (money) => Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money);
export const formatRupiahWithoutPrefix = (value, dec) => {
  dec = dec || 0
  if (value === null) {
    return 0
  }
  return '' + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
}

export const isNumber = (evt) => {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault();;
  } else {
    return true;
  }
}

export const shortlessDate = (date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
  const convert = new Date(date)
  return `${convert.getDate()} ${months[convert.getMonth()]} ${convert.getFullYear()}`
}

export const shortlessDateTime = (date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des']
  const convert = new Date(date)
  const hour = convert.getHours() < 10 ? '0' + convert.getHours() : convert.getHours()
  const minute = convert.getMinutes() < 10 ? '0' + convert.getMinutes() : convert.getMinutes()
  const second = convert.getSeconds() < 10 ? '0' + convert.getSeconds() : convert.getSeconds()
  return `${convert.getDate()} ${months[convert.getMonth()]} ${convert.getFullYear()}, ${hour}:${minute}:${second}`
}

export const isBlankString = (str) => {
  return (!str || /^\s*$/.test(str));
}

export const seoURL = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const _ = null
