require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#2D3D78',
        accent: '#0d6efd',
        secondary: '#c48eff',
        success: '#5cb85c',
        info: '#5bc0de',
        warning: '#f0ad4e',
        error: '#d9534f',
      },
      dark: {
        primary: '#2D3D78',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#5cb85c',
        info: '#5bc0de',
        warning: '#f0ad4e',
        error: '#d9534f',
      },
    },
  },
}
